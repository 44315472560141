<template>
	<div>
		<table class="altbl noborderv market-table">
			<thead>
				<tr>
					<td v-for="col in cols" :key="col.title" class="headingtext" @click="sortColumn(col.fldid)">
						<span :class="{pointer : col.sortable}">{{col.title}}</span>
					</td>
				</tr>
			</thead>
			<tbody>
				<tr v-for="rowitem in rows" :key="rowitem.id">
					<td>
						<div class="checklist-item" @click="toggleMarketInSelection(rowitem)">
							<span class="toggler">
								<i v-if="store.selectionObs.markets.some(item => item.id === rowitem.id)" class="mdi mdi-check-bold"></i>
							</span>
						</div>
					</td>
					<td>
						<span>
							<span class="mktlabel" @click="toggleMarketInSelection(rowitem)">{{rowitem.name}}</span>
							<InfoIcon v-on:clicked="showMarketSummary(rowitem)" title="Click to view market information and recent survey results"></InfoIcon>
						</span>
					</td>
					<td>{{rowitem.state}}</td>
					<td><p style="text-align: right;">{{numdisplay(rowitem.indpop)}}</p></td>
					<td>
						<div v-for="o in rowitem.ownership.owns" :key="o" class="item-logo">
							<img v-if="!['rsn','sky'].includes(o)" :src="store.logosRoot+'station-logos/'+o+'.png?v=3'" :style="'width:50px;height:40px;'" />
						</div>
					</td>
					<td>
						<div v-if="rowitem.isGfk === false">
							<div v-for="o in rowitem.ownership.reps" :key="o" class="item-logo">
								<img v-if="!['rsn','sky'].includes(o)" :src="store.logosRoot+'station-logos/'+o+'.png?v=3'" :style="'width:50px;height:40px;'" />
							</div>
						</div>
					</td>
					<td><p>{{getTVAggMktName(rowitem.tvAggId)}}</p></td>
					<td><p>{{boolToYes(rowitem.surveyed)}}</p></td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import InfoIcon from "@/components/InfoIcon";
export default {
	name: "MarketsTable",
	components: {
		InfoIcon
	},
	props: {
		filtertext: String,
		inrows: Array,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			// table stuff
			sortColName: null,
			sortColOrder: 'desc',
			cols: [
				{fldid: 'selected', title: 'Selected', sortable: false},
				{fldid: 'name', title: 'Market Name', sortable: true},
				{fldid: 'state', title: 'State', sortable: true},
				{fldid: 'indpop', title: '10+ Pop', sortable: true, thClass: 'text-right', sortDirection: 'desc'},
				{fldid: '', title: 'Operators', sortable: true},
				{fldid: '', title: 'Representation', sortable: true},
				{fldid: 'tvAggId', title: 'TV Agg Mkt', sortable: true, sortDirection: 'desc'},
				{fldid: 'surveyed', title: 'Surveyed', sortable: true, sortDirection: 'desc'},
			],
			//end table stuff
		}
	},
	computed: {
		rows() {
			//put in order of GFK first, then the rest
			let myrows = []
			for(let gid of this.store.gfkMktIds) {
				if(this.inrows.find(item => item.id === gid)) myrows.push(this.inrows.find(item => item.id === gid))
			}
			for(let row of this.inrows) {
				if(!this.store.gfkMktIds.includes(row.id)) myrows.push(row)
			}
			let forceKeepIds = this.getObIdsToArr(this.store.selectionObs.markets)
			return this.tblPrepareFilteredRows(myrows, this.filtertext, this.sortColName, this.sortColOrder, forceKeepIds)
		},
	},
	methods: {
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		showMarketSummary(mktob) {
			this.$emit('showsum', mktob)
		}
	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.pointer {
	cursor: pointer;
}
.mktlabel {
	cursor: pointer;
}
.mktlabel:hover {
	text-decoration: underline;
}
.item-logo {
	display: inline-block;
}
.item-logo img {
	width: 50px;
	height: 40px;
	object-fit: contain;
	margin-right: 5px;
}
</style>